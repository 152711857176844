export const convertNumberToThousand = (
  value: number | string,
  separator: string = "."
) => {
  if (typeof value === "number") {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, separator)
  }

  return value.replace(/\B(?=(\d{3})+(?!\d))/g, separator)
}

export const getInitialsName = (name: string) => {
  return name
    .split(" ")
    .map((name) => name[0])
    .join("")
    .toUpperCase()
}
